import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ActivitiesSlider from "../components/activities-slider";

const GuideTemplate = ({ data, pageContext: context }) => {
  const { title, main_title, thumbnail, content } =
    data.contentfulCarnetDeVoyage;

  return (
    <Layout>
      <Seo
        title={title}
        description={data.contentfulHotel.meta_description}
        image={thumbnail && thumbnail.file.url}
      />
      <article>
        <section className="section section-white">
          <div
            className={`introduction${
              thumbnail ? " introduction-hero" : " introduction-clouds"
            }`}
          >
            <div className="introduction-container">
              <div className="introduction-inner">
                {thumbnail && (
                  <Img
                    className="introduction-image"
                    fluid={thumbnail.fluid}
                    loading="eager"
                    alt={title}
                  />
                )}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <p className="eyebrow">{title}</p>
                      <h1>{main_title}</h1>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-gradient overflow-hidden">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {renderRichText(content, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <p className="lead">{children}</p>
                    ),
                  },
                })}
              </div>
            </div>
            <aside className="mt-16 mt-md-30">
              <h2>
                <FormattedMessage id="guide.activity" />
              </h2>
              <div className="row mt-8 mt-md-10">
                <div className="col-8 col-sm-10 col-lg-12 position-static">
                  <LazyLoad>
                    <ActivitiesSlider />
                  </LazyLoad>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default GuideTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!, $hotel: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      meta_description
      address
      phone
      booking_id
      place_id
      business_url
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulCarnetDeVoyage(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      title
      main_title
      thumbnail {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        raw
      }
    }
    allContentfulActivite(
      filter: { hotel: { slug: { eq: $hotel } }, node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        node_locale
      }
    }
  }
`;
